import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Editor as TinymceEditor } from '@tinymce/tinymce-react'
import { useEffect, useState } from 'react'
import { BiChevronDown, BiHelpCircle, BiHistory, BiX } from 'react-icons/bi'
import { BsPencilFill } from 'react-icons/bs'
import { useLocation, useNavigate } from 'react-router-dom'

import NoAccessSvg from '~/assets/NoAccess.svg'
import { AdminNotFoundComponent } from '~components/AdminNotFoundComponent'
import { Loading } from '~components/Loading'
import { Navbar } from '~components/Navbar'
import { HAS_SEEN_EDITOR_INTRO } from '~constants/localStorage'
import { EditorLetterPreview } from '~features/create/components/EditorLetterPreview'
import { useCreatePdfPreview } from '~features/create/hooks/create.hooks'
import { EditorIntroModal } from '~features/editor/components/editor/EditorIntroModal'
import { useUploadImage } from '~features/editor/hooks/images.hooks'
import { useTinymceApiKey } from '~features/editor/hooks/tinymce.hooks'
import {
  useGetTemplateById,
  useTemplateId,
} from '~features/issue/hooks/templates.hooks'
import { useToast } from '~hooks/useToast'
import { ACCEPTED_TEMPLATE_NAME_REGEX } from '~shared/constants/regex'
import { DEFAULT_LETTER_TEMPLATE_STYLE } from '~shared/constants/templates'
import { GetTemplateDto } from '~shared/dtos/templates.dto'
import { GrowthBookFeatures } from '~shared/types/feature-flag'
import { sanitizeTemplateVariablesBeforeSaving } from '~utils/htmlUtils'

import CreateTemplateModal, {
  SOURCE_TYPE,
} from './components/editor/CreateTemplateModal'
import ExitEditorModal from './components/editor/ExitEditorModal'
import SaveTemplateModal from './components/editor/SaveTemplateModal'
import { TemplateHistoryDrawer } from './components/editor/TemplateHistoryDrawer'

export const EditTemplatePage = (): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()
  const { templateId } = useTemplateId()
  const { template, isTemplatesLoading } = useGetTemplateById(templateId)
  const [templateContent, setTemplateContent] = useState('')
  const [templateName, setTemplateName] = useState('')
  const [templateVersion, setTemplateVersion] = useState(
    Number.POSITIVE_INFINITY,
  ) // use POSITIVE_INFINITY at the start so that the first toast will not show from the useEffect()
  const [initialTemplate, setInitialTemplate] = useState<GetTemplateDto | null>(
    null,
  )

  const toast = useToast()

  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  const isCloned = location.state ? (location.state.isCloned as boolean) : false
  const isFromLibrary = location.state
    ? (location.state.isFromLibrary as boolean)
    : false
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */
  useEffect(() => {
    if (template && !initialTemplate) {
      // on first load, initialTemplate is undefined
      setTemplateContent(template.html)
      setTemplateName(template.name)
      setTemplateVersion(template.version)
      setInitialTemplate(template)
    }
  }, [template])

  // show toast if template is updated in different window
  useEffect(() => {
    if (template && template.version > templateVersion) {
      toast({
        title:
          'The template was edited in another window. Please copy your changes and refresh the editor to continue.',
        status: 'info',
      })
    }
  }, [template])

  const {
    isOpen: isEditorIntroOpen,
    onOpen: onEditorIntroOpen,
    onClose: onEditorIntroClose,
  } = useDisclosure()

  const {
    isOpen: isSaveOpen,
    onOpen: onSaveOpen,
    onClose: onSaveClose,
  } = useDisclosure()

  const {
    isOpen: isExitModalOpen,
    onOpen: onExitModalOpen,
    onClose: onExitModalClose,
  } = useDisclosure()

  const {
    isOpen: isTemplateHistoryOpen,
    onOpen: onTemplateHistoryOpen,
    onClose: onTemplateHistoryClose,
  } = useDisclosure()

  const {
    isOpen: isCopyTemplateOpen,
    onOpen: onCopyTemplateOpen,
    onClose: onCopyTemplateClose,
  } = useDisclosure()

  const showCloneTemplate = useFeatureIsOn(
    GrowthBookFeatures.cloneTemplate as string,
  )
  const enableMenuBarInEditor = useFeatureIsOn(
    GrowthBookFeatures.enableMenuBar as string,
  )

  useEffect(() => {
    const editorIntroAlreadySeen = localStorage.getItem(HAS_SEEN_EDITOR_INTRO)
    if (!editorIntroAlreadySeen) {
      localStorage.setItem(HAS_SEEN_EDITOR_INTRO, 'true')
      setTimeout(() => {
        onEditorIntroOpen()
      }, 1000)
    }
  }, [])

  const { tinymceApiKey, isLoadingTinymceApiKey } = useTinymceApiKey()
  const uploadImage = useUploadImage()

  const { createPdfPreview, isPdfPreviewLoading } = useCreatePdfPreview()

  if (isLoadingTinymceApiKey || !tinymceApiKey || isTemplatesLoading)
    return <Loading />

  const isTemplateNameInvalid =
    templateName.trim() === '' ||
    !ACCEPTED_TEMPLATE_NAME_REGEX.test(templateName.trim())

  if (!isTemplatesLoading && !template)
    return (
      <Box>
        <Navbar />
        <AdminNotFoundComponent
          heading="You don't have access to this template"
          subtitle="Please check your permissions or contact the owner of this template."
          image={NoAccessSvg}
          buttonText="Back to template library"
          buttonOnClick={() => navigate('/admin/templates')}
        />
      </Box>
    )
  return (
    <VStack h="100%" display="flex" flexDirection="column">
      <HStack
        w={'100%'}
        py={4}
        px={10}
        display={'flex'}
        justify={'space-between'}
        position={'fixed'}
        zIndex={10}
        backgroundColor={'grey.50'}
        pointerEvents="auto"
      >
        <HStack spacing={0}>
          <Button
            onClick={() => {
              if (
                templateContent === initialTemplate?.html &&
                templateName === initialTemplate?.name
              ) {
                navigate(-1)
              } else {
                onExitModalOpen()
              }
            }}
            variant={'unstyled'}
            border={'none'}
            aria-label="Close editor"
          >
            <BiX size={'1.7rem'} />
          </Button>
          <Tooltip
            label={
              templateName.trim() === ''
                ? 'Title cannot be empty'
                : !ACCEPTED_TEMPLATE_NAME_REGEX.test(templateName.trim())
                ? 'Title should only contain letters, numbers, spaces, or characters: —@\'"’-()[]:#_&|,.?!/+'
                : 'Write an appropriate title for your template. It will be shown to citizens.'
            }
            placement="right"
          >
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <BsPencilFill size="1rem" />
              </InputLeftElement>
              <Input
                value={templateName}
                onChange={(event) => setTemplateName(event.target.value)}
                fontWeight="500"
                id="template-name-editor"
                sx={{
                  // if not focused and invalid/ empty, style as error
                  // if not focused and valid, style as normal text
                  ':not(:focus)': {
                    border: isTemplateNameInvalid ? '1px solid' : 'none',
                    borderColor: isTemplateNameInvalid
                      ? 'red.500'
                      : 'transparent',
                    backgroundColor: isTemplateNameInvalid
                      ? 'white'
                      : 'transparent',
                    cursor: 'text',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                  // 44px is size of close icon, 40px is padding on top bar
                  width: 'calc(50vw - 44px - 40px)',
                }}
              />
            </InputGroup>
          </Tooltip>
        </HStack>
        <HStack spacing={4}>
          <Button
            alignSelf="start"
            onClick={onEditorIntroOpen}
            variant={'outline'}
            px={0}
            border="none"
            aria-label="Get help"
          >
            <BiHelpCircle size="1.7rem" />
          </Button>
          <Button
            alignSelf="start"
            onClick={onTemplateHistoryOpen}
            variant={'outline'}
            px={0}
            border="none"
            aria-label="Template history"
          >
            <BiHistory size="1.7rem" />
          </Button>
          <Button
            variant={'outline'}
            onClick={() => createPdfPreview({ html: templateContent })}
            isLoading={isPdfPreviewLoading}
          >
            Preview PDF
          </Button>
          {!showCloneTemplate || isCloned ? (
            <Button
              alignSelf="start"
              onClick={onSaveOpen}
              isDisabled={
                (templateContent === initialTemplate?.html &&
                  templateName === initialTemplate?.name) ||
                !templateContent ||
                isTemplateNameInvalid
              }
            >
              Save Template
            </Button>
          ) : (
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<BiChevronDown />}
                isDisabled={
                  (templateContent === initialTemplate?.html &&
                    templateName === initialTemplate?.name) ||
                  !templateContent ||
                  isTemplateNameInvalid
                }
              >
                Save Template
              </MenuButton>
              <MenuList mt={'-1.5'}>
                <MenuItem onClick={onSaveOpen} width={'170px'}>
                  Save as current
                </MenuItem>
                <MenuItem onClick={onCopyTemplateOpen}>Save as new</MenuItem>
              </MenuList>
            </Menu>
          )}
        </HStack>
      </HStack>
      <HStack
        w="full"
        justify="space-between"
        pt={13}
        display="flex"
        overflow="auto"
        spacing="0"
        h="full"
      >
        <VStack flex={5} height="100%" bg={'white'}>
          <TinymceEditor
            apiKey={tinymceApiKey}
            initialValue={initialTemplate?.html}
            init={{
              plugins: 'code table help link pagebreak',
              height: '100%',
              menubar: enableMenuBarInEditor,
              statusbar: false,
              width: '100%',
              resize: false,
              toolbar1:
                'undo redo | bold italic underline strikethrough|| alignleft aligncenter alignright alignjustify | outdent indent| fontselect fontsizeselect formatselect |  numlist bullist checklist| blocks fontfamily fontsizeinput forecolor backcolor| hr link table pagebreak image',
              font_family_formats:
                'Andale Mono=andale mono,"Monaco",monospace; Arial=arial,helvetica,sans-serif; Arial Black="Arial Black",impact,sans-serif; Baskerville="Baskerville",times new roman,serif; Courier New=courier, monospace; Georgia=georgia,palatino,serif; Helvetica=helvetica,arial,sans-serif; Impact=impact,"Arial Black",sans-serif; Monaco=Monaco,andale mono,monospace; Papyrus="Papyrus",fantasy; Tahoma=tahoma,arial,helvetica,sans-serif; Times New Roman=times new roman,times,sans-serif; Trebuchet MS=trebuchet ms,geneva,sans-serif; Verdana=verdana,geneva,sans-serif; Brush Script MT=brush script mt,cursive; Lucida Calligraphy="Lucida Calligraphy",cursive;',
              pagebreak_separator:
                '<div style="page-break-after: always; height: 0; display: block;">&nbsp;</div>',
              valid_children: '+body[style]',
              // set default styling for the editor
              // TODO: we might need to set up separate styling for the certificate layout
              setup: function (editor) {
                editor.on('BeforeSetContent', function (e) {
                  if (e.content.trim() === '') {
                    e.content = DEFAULT_LETTER_TEMPLATE_STYLE
                  }
                })
                editor.ui.registry.addButton('image', {
                  icon: 'image',
                  onAction: function () {
                    const input = document.createElement('input')
                    input.type = 'file'
                    input.accept = 'image/png, image/jpeg'
                    input.onchange = async (event) => {
                      // input only accepts a single file by default
                      const file = (event.target as HTMLInputElement).files?.[0]
                      if (!file) return
                      const { url } = await uploadImage(file)
                      if (!url) return
                      editor.insertContent(`<img src="${url}" />`)
                    }
                    input.click()
                  },
                })
              },
            }}
            onDrop={async (e, editor) => {
              const files = e.dataTransfer?.files
              if (!files) return
              e.preventDefault()
              e.stopImmediatePropagation()
              e.stopPropagation()
              for (const file of files) {
                const { url } = await uploadImage(file)
                if (url === null) continue
                editor.insertContent(`<img src="${url}" />`)
              }
            }}
            onDblclick={(e, editor) => {
              const target = e.target as HTMLElement
              if (target.nodeName !== 'IMG') return
              const input = document.createElement('input')
              input.type = 'file'
              input.accept = 'image/png, image/jpeg'
              input.onchange = async (event) => {
                // input only accepts a single file by default
                const file = (event.target as HTMLInputElement).files?.[0]
                if (!file) return
                const { url } = await uploadImage(file)
                if (!url) return

                const img = target as HTMLImageElement
                editor.dom.setAttrib(img, 'src', url)
                // workaround as setting dom attribute does not seem to automatically trigger onEditorChange
                setTemplateContent(editor.getContent())
              }
              input.click()
            }}
            onEditorChange={setTemplateContent}
          />
        </VStack>
        <VStack flex={4} height={'100%'} bg={'slate.800'} overflow={'auto'}>
          <EditorLetterPreview templateContent={templateContent} />
        </VStack>
      </HStack>
      <SaveTemplateModal
        onClose={onSaveClose}
        isOpen={isSaveOpen}
        templateName={templateName}
        template={template}
        templateVersion={templateVersion}
        templateHtml={sanitizeTemplateVariablesBeforeSaving(templateContent)}
      />
      <CreateTemplateModal
        onClose={onCopyTemplateClose}
        isOpen={isCopyTemplateOpen}
        originalTemplateName={template.name}
        defaultTemplateName={
          templateName !== template.name
            ? templateName
            : `Copy of ${template.name}`
        }
        source={SOURCE_TYPE.CLONE_EDITOR}
        templateHtml={sanitizeTemplateVariablesBeforeSaving(templateContent)}
      />
      <EditorIntroModal
        isOpen={isEditorIntroOpen}
        onClose={onEditorIntroClose}
        showWarning
      />
      <ExitEditorModal
        isOpen={isExitModalOpen}
        onClose={onExitModalClose}
        onConfirm={() => {
          if (isFromLibrary) {
            navigate(-1)
          } else {
            navigate('/admin/templates')
          }
        }}
      />
      <TemplateHistoryDrawer
        isOpen={isTemplateHistoryOpen}
        onClose={onTemplateHistoryClose}
        templateId={template.id}
      />
    </VStack>
  )
}
